<!-- 设置挂单 -->
<script setup>
import { provider, ethers } from "@/utils/ethers.js";
import { reactive, ref, computed, watchEffect, onMounted } from "vue";
import { useAppStore } from "@store/appStore";
import useMarketStore from "@store/cattleMarketStore";
import useMintStore from "@contractStore/cattleMintStore";
import useCattleStore from "@contractStore/cattleStore";
import usePlanetStore from "@contractStore/planetStore";
import useBlindBoxStore from "@contractStore/blindBoxStore";
import {
  IGO_BLIND_BOX_CONT,
  BV_BLIND_BOX_CONT,
  OAT_NFT_CONT,
  CATTLE_CONT,
  STAR_CONT,
  BLIND_BOX_CONT,
  NFT_MINT_CONT,
  BVT_TOKEN_CONT,
  BVG_TOKEN_CONT,
  USDT_TOKEN_CONT
} from "@/contracts/polygonAddress.js";
import { TRADE_TYPE } from "@/constants";
import CoinToken from "@/contracts/CoinToken.js";
import { allowAll, authAll } from "@/contracts/helper.js";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import $load from "@cps/GlobalLoading";
import { bpLt } from "@/utils/bpMath";
import {
  padNumber,
  toThousands,
  handleCopy,
  plusXing,
  getTradeTypeCoin
} from "@/utils/tools.js";
import { showStakePop, setShowStake } from "@cps/PopUp/usePop.js";
import StakePop from "@cps/PopUp/StakePop.vue";

onMounted(() => {
  handleCopy();
});

const $tx = useI18n();
const $route = useRoute();
const $router = useRouter();
const appStore = useAppStore();
const cattleStore = useCattleStore();
const packageStore = useMarketStore();
const mintStore = useMintStore();
const planetStore = usePlanetStore();
const blindBoxStore = useBlindBoxStore();
cattleStore.createContract();
planetStore.createContract();
mintStore.createContract();

// 要展示的类型: blindBox、cattle、hero、planet
const showCate = computed(() => {
  return $route.query.cate;
});
const showType = computed(() => {
  return $route.query.type;
});

// 控制显示下拉选择币菜单
const coinOptions = reactive([
  // {
  //   id: 1,
  //   type: 3,
  //   icon: 'bnb',
  //   label: 'BNB',
  // },
  {
    id: 2,
    type: 4, //usdt
    icon: getTradeTypeCoin(4),
    label: TRADE_TYPE[4],
    token_cont: USDT_TOKEN_CONT
    // decimal: 6
  },
  {
    id: 3,
    type: 5,
    icon: "aia",
    label: "AIA",
    decimal: 18
  },
  {
    id: 4,
    type: 2, //原BVG
    icon: getTradeTypeCoin(2),
    label: TRADE_TYPE[2],
    token_cont: BVG_TOKEN_CONT
  },
  {
    id: 5,
    type: 1, // 原BVT
    icon: getTradeTypeCoin(1),
    label: TRADE_TYPE[1],
    token_cont: BVT_TOKEN_CONT
  }
]);

/**
 * 获取页面详情
 */
const hasAllowance = ref(false);
const goodsType = ref(1);
const showSelPanel = ref(false);
const curSelect = ref(coinOptions[0]);
const amount = ref("");
const rate = ref(0); // 价格比例
const usdtPrices = ref(0); // 等价的 USDT
const paymentAddress = ref(appStore.defaultAccount);

watchEffect(async () => {
  if (appStore.defaultAccount == null) return;
  await init();
});
const currentCoinToken = computed(() => {
  let { token_cont } = curSelect.value;
  return new CoinToken(token_cont);
});

async function init() {
  try {
    if (showType.value === "cattle") {
      const ownerAddr = await cattleStore.getOwnerAddr($route.query.id);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        await packageStore.fetchCattleInfo($route.query.id);
        goodsType.value = 1;
      } else {
        $router.push({ name: "nftMarket" });
      }
    } else if (showType.value === "blindBox") {
      const ownerAddr = await blindBoxStore.getOwnerAddr($route.query.id);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        blindBoxStore.getBoxInfo($route.query.id, showType.value);
        goodsType.value = 2;
      } else {
        $router.push({ name: "nftMarket" });
      }
    } else if (showCate.value === "planet") {
      const ownerAddr = await planetStore.getOwnerAddr($route.query.id);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        await planetStore.getPlanetInfo($route.query.id, showType.value);
        goodsType.value = 3;
      } else {
        $router.push({ name: "nftMarket" });
      }
    }
    await packageStore.getCurrency();
    selectChange(coinOptions[0]);
    await isApprove();
  } catch (error) {
    console.log("init set listing ..", error);
  }
}

/**
 * 鼠标移入状态栏
 */
function enterTrade() {
  showSelPanel.value = true;
}

/**
 * 鼠标移出状态栏
 */
function leaveTrade() {
  showSelPanel.value = false;
}

// 图片
const cardImg = computed(() => {
  if (showCate.value === "hero") {
    return packageStore.cattleInfo.image;
  } else if (showCate.value === "cattle") {
    return packageStore.cattleInfo.image;
  } else if (showCate.value === "planet") {
    return planetStore.planetInfo.image;
  } else if (showCate.value === "blindBox") {
    return "https://game.legendranch.app/legendaryranch/boxgif/box.png";
  } else if (showType.value === "others") {
    if (showType.value === "oat") {
      return oatMintStore.cardInfo.image;
    } else if (
      showType.value === "Bovine-Hero" ||
      showType.value === "Cattle" ||
      showType.value === "Items-Pack" ||
      showType.value === "Skins-Pack"
    ) {
      return blindBoxStore.blindBoxInfo.image;
    }
  } else {
    return "https://s3.bmp.ovh/imgs/2022/01/eac36c3b71fe903c.png";
  }
});

// 名称
const name = computed(() => {
  // 星球
  if (showCate.value === "planet") {
    return $tx.t(planetStore.planetInfo?.name);
  } else if (showCate.value === "hero") {
    // 創世牛
    return `${$tx.t("card.3")} #${$route.query.id}`;
  } else if (showCate.value === "cattle") {
    // 牛
    return `${$tx.t("card.4")} #${$route.query.id}`;
  } else if (showCate.value === "blindBox") {
    // 盲盒
    return $tx.t(blindBoxStore.blindBoxInfo?.name);
  } else {
    return "N/A";
  }
});

// 合约地址
const contractAddress = computed(() => {
  if (showCate.value === "planet") {
    return STAR_CONT.address;
  } else if (showCate.value === "hero") {
    return CATTLE_CONT.address;
  } else if (showCate.value === "cattle") {
    return CATTLE_CONT.address;
  } else if (showType.value === "blindBox") {
    return BLIND_BOX_CONT.address;
  } else if (showType.value === "Mystery-Box") {
    return IGO_BLIND_BOX_CONT.address;
  } else if (
    showType.value === "Skins-Pack" ||
    showType.value === "Items-Pack" ||
    showType.value === "Bovine-Hero" ||
    showType.value === "Cattle"
  ) {
    return BV_BLIND_BOX_CONT.address;
  } else if (showType.value === "oat") {
    return OAT_NFT_CONT.address;
  } else {
    return "";
  }
});

/**
 * 下拉排序列表触发
 * @param {Object} opt 选项框的每一项
 */
async function selectChange(opt) {
  showSelPanel.value = false;
  curSelect.value = opt;
  const amt = amount.value === "" ? 0 : Number(amount.value);
  usdtPrices.value = packageStore.countingPrice({
    types: opt.type,
    amount: amt
  });
}

const countNum = ref(1);
/**
 * 改变数量
 * @param {Boolean} isRise 是否增加：true，false
 */
function changeNum(isRise) {
  if (!isRise && countNum.value > 0) {
    countNum.value -= 1;
  } else if (isRise && countNum.value < 1) {
    countNum.value += 1;
  }
}

// USDT价格动态变化
watchEffect(() => {
  let amt = 0;
  if (amount.value !== "") {
    amt = Number(amount.value);
  }
  selectChange(curSelect.value);
});

// 禁止输入字符串，Number 只保存小数点后3位
const valueChange = e => {
  // console.log('=====', e);
  let value = amount.value;
  value = value.replace(/[^\d.]/g, "");
  value = value.replace(/^\./g, "");

  value = value.replace(/\.{3,}/g, ".");

  value = value
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".");

  value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, "$1$2.$3");

  if (value >= 100000000) {
    value = "99999999";
  }
  setTimeout(() => {
    amount.value = value;
  });
};

/**
 * 授权
 */
async function handleApproveforAll() {
  if (hasAllowance.value === false) {
    try {
      await setApprove();
      ElMessage({
        message: $tx.t("msg.3"),
        type: "success"
      });
    } catch (error) {
      console.log(error);
      ElMessage({
        message: $tx.t("msg.4"),
        type: "error"
      });
    }
    $load({ isShow: false });
    return;
  }
}

/**
 * 查看是否已经授权
 */
async function isApprove() {
  if (showType.value === "cattle") {
    hasAllowance.value = await cattleStore.isApprovedAll(NFT_MINT_CONT.address);
    console.log("catt..", hasAllowance.value);
  } else if (showCate.value === "planet") {
    hasAllowance.value = await allowAll(
      planetStore.contract,
      NFT_MINT_CONT.address,
      appStore.defaultAccount
    );
  } else if (showType.value === "blindBox") {
    hasAllowance.value = await blindBoxStore.isApproveAll(
      appStore.defaultAccount,
      showType.value,
      NFT_MINT_CONT.address
    );
  }
}

/**
 * 设置Approve
 */
async function setApprove() {
  $load({ isShow: true });
  if (showType.value === "cattle") {
    const res = await authAll(cattleStore.contract, NFT_MINT_CONT.address);
    if (res) {
      hasAllowance.value = true;
    }
  } else if (showCate.value === "planet") {
    const res = await authAll(planetStore.contract, NFT_MINT_CONT.address);
    if (res) {
      hasAllowance.value = true;
    }
  } else if (showType.value === "blindBox") {
    const res = await blindBoxStore.authAll(
      showType.value,
      NFT_MINT_CONT.address
    );
    if (res) {
      hasAllowance.value = true;
    }
  }

  $load({ isShow: false });
}

async function handleOpen() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  setShowStake(true);
}

/**
 * 确认操作
 */
const isClickConfirm = ref(false);
async function handleConfirm() {
  const { ethers } = appStore.ethObj;
  // 授权
  if (hasAllowance.value === false) {
    return;
  }
  // 判斷是否點擊質押
  if (hasAllowance.value === true && isClickConfirm.value === false) {
    isClickConfirm.value = true;
    return;
  }
  // 挂售价格不能为0
  if (amount.value === "" || !amount.value) {
    ElMessage({
      message: $tx.t("msg.30"),
      type: "error"
    });
    return;
  }
  if (!ethers.utils.isAddress(paymentAddress.value)) {
    ElMessage({
      message: $tx.t("msg.33"),
      type: "error"
    });
    return;
  }
  $load({ isShow: true });
  // 挂售
  try {
    let lowerLimit = await mintStore.getLowerLimit(
      goodsType.value,
      curSelect.value.type
    );
    // 小牛挂售价格预设 150
    if (showCate.value === "cattle") {
      if (curSelect.value.type === 4) {
        lowerLimit = 150;
      } else {
        lowerLimit = 2500;
      }
    }
    if (bpLt(amount.value, lowerLimit)) {
      ElMessage({
        message: $tx.t("dialog.16") + lowerLimit,
        type: "error"
      });
      $load({ isShow: false });
      return;
    }
    const decimals =
      curSelect.value.decimal || (await currentCoinToken.value.getDecimals());
    const amt = ethers.utils.parseUnits(String(amount.value), decimals);
    console.log("挂售参数", {
      goodsType: goodsType.value,
      id: $route.query.id,
      tradeType: curSelect.value.type,
      price: amt.toString(),
      payee: paymentAddress.value
    });
    const resp = await mintStore.sellCard({
      goodsType: goodsType.value,
      id: $route.query.id,
      tradeType: curSelect.value.type,
      price: amt,
      payee: paymentAddress.value
    });

    ElMessage({
      message: $tx.t("msg.26"),
      type: "success"
    });
    setShowStake(false);
    // 跳转我的挂单页
    $router.push({
      name: "myListing",
      query: {
        type: $route.query.cate
      }
    });
  } catch (error) {
    console.log(error);
    let info = error?.data?.message || error?.message;
    // 点击了拒绝信息
    if (info?.includes?.("User denied transaction")) {
      info = "User denied transaction signature.";
    }

    // 移动端点击了拒绝信息
    if (error?.includes?.("cancel")) {
      info = "User denied transaction signature.";
    }
    // 移动端报错信息
    if (error?.reason) {
      info = error?.reason;
    }

    if (info?.includes?.("rejected")) {
      info = $tx.t("msg.75");
    }
    if(info?.includes?.('ERR_NOT_OPEN')) {
       info = $tx.t("msg.74");
    }
    // 避免信息太长看懵用户
    info = String(info).length > 100 ? $tx.t("msg.27") : info;
    ElMessage({
      message: info,
      type: "error"
    });
  }
  $load({ isShow: false });
}

/**
 * 取消操作
 */
function handleCancel() {
  $router.back();
}

/**
 * 返回到市场
 */
function toMyListing() {
  $router.back();
}
</script>

<template>
  <div class="listing-detail-wrap">
    <div class="main">
      <!-- 返回 -->
      <div class="back">
        <div @click="toMyListing">
          <i class="iconfont icon-simple-arrow"></i>
          <span style="font-size: 0.26rem">{{ $t('panel.27') }}</span>
        </div>
      </div>
      <div class="lst-title">{{ $t('detail.38') }}</div>
      <section class="detial">
        <img :src="cardImg" alt class="face" />

        <!-- 信息详情 -->
        <div class="name">{{ name }}</div>
        <div class="label">{{ $t('detail.18') }}</div>
        <div class="addr">
          <div>{{ contractAddress }}</div>
          <i class="iconfont icon-cpy cpy-btn" :data-clipboard-text="contractAddress"></i>
        </div>
        <div class="label">{{ `Token ID ${$route.query.id}` }}</div>

        <!-- <div style="font-size: 0.24rem; margin-top: 0.24rem">Qty</div> -->

        <!-- 购买数量 -->
        <!-- <div class="count-container" v-show="false">
          <button class="minus" @click="changeNum(false)">-</button>
          <div class="num">{{ countNum }}</div>
          <button class="plus" @click="changeNum(true)">+</button>
        </div>-->

        <!-- 设置价格 -->
        <div class="subtitle">{{ $t('detail.39') }}</div>
        <div class="set-price-container">
          <input type="text" placeholder="0.01" v-model="amount" @input="valueChange" />
          <!-- 下拉选择排序 -->
          <div class="select-wrap" @mouseenter="enterTrade" @mouseleave="leaveTrade">
            <div :class="['coins', `icons-${curSelect.icon}`]"></div>
            <span>{{ $t(curSelect.label) }}</span>
            <i class="iconfont icon-simple-arrow"></i>

            <!-- 选择面板-->
            <transition name="el-zoom-in-top">
              <div class="option-wrap" v-show="showSelPanel">
                <li v-for="opt in coinOptions" @click.stop="selectChange(opt)" :key="opt.label">
                  <div :class="['iconfont', 'coins', `icons-${opt.icon}`]"></div>
                  <div>{{ opt.label }}</div>
                </li>
              </div>
            </transition>
          </div>
        </div>
        <div class="need-tips">
          <div>{{ `${usdtPrices} USDT` }}</div>
        </div>

        <div class="payment-address">收款地址</div>
        <div class="set-payment-container">
          <input type="text" placeholder v-model="paymentAddress" />
        </div>

        <div style="font-size: 0.24rem; margin-bottom: 0.07rem">{{ $t('detail.40') }}</div>
        <div style="font-size: 0.18rem">{{ $t('detail.41') }}</div>

        <div class="btns">
          <button @click="handleOpen">{{ $t('dialog.1') }}</button>
          <button @click="handleCancel">{{ $t('dialog.6') }}</button>
        </div>
      </section>
    </div>
  </div>
  <!-- 质押弹窗 -->
  <StakePop
    :isStake="isClickConfirm"
    :isApprove="hasAllowance"
    :title="'detail.42'"
    :stepTitle="['blindBox.49', 'detail.43']"
    :stepDesc="['blindBox.50', 'detail.44']"
    @approve="handleApproveforAll"
    @stake="handleConfirm"
  ></StakePop>
</template>

<style lang="scss" scoped>
.listing-detail-wrap {
  @include -padding-bottom(2.05rem, 1.5rem, 1.05rem);
  border-top: solid 0.01px transparent; // 仅仅为了解决margin塌陷问题
  color: #fff;

  @include nftImgBg;

  .main {
    @include -width(calc(100% - 0.6rem), 9rem, 12rem);
    margin: 0 auto;
  }

  section {
    @include -width(100%, 6.84rem, 6.84rem);
    margin: 0 auto;
  }

  .back {
    margin: 0.5rem 0 0.35rem;
    @include flexPos(flex-start, center);
    font-size: 0.26rem;
    cursor: pointer;

    > div {
      @include flexPos(flex-start, center);
    }

    .iconfont {
      margin-right: 0.1rem;
      font-size: 0.24rem;
    }
  }

  .face {
    width: 4.85rem;
    margin-top: 0.5rem;
    margin: 0 auto;
    // @include -margin-left(0, 1.03rem, 2.03rem);
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-radius: 10px;
    background-image: linear-gradient(to bottom, transparent, #5a25b1 100%);
  }

  .lst-title {
    font-size: 0.4rem;
    line-height: 0.46rem;
    padding-bottom: 0.5rem;
  }

  .label {
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    line-height: 0.4rem;
    @include -media($pc, line-height, 0.21rem);
  }
  .name {
    @include -width(100%, 100%, auto);
    @include flexPos(center);
    @include -media($pc, display, flex) {
      @include flexPos(flex-start, center);
    }
    @include -font-size(0.6rem, 0.6rem, 0.36rem);
    line-height: 0.7rem;
    @include -media($pc, line-height, 0.42rem);
    padding: 0.4rem 0 0.1rem 0;
  }

  .subtitle {
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    line-height: 0.4rem;
    @include -media($pc, line-height, 0.21rem);
    padding-top: 0.24rem;
  }

  .payment-address {
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    line-height: 0.4rem;
    @include -media($pc, line-height, 0.21rem);
    padding-bottom: 0.24rem;
  }

  .set-payment-container {
    display: flex;
    @include -width(100%, 100%, 6.84rem);
    @include -height(0.8rem, 0.8rem, 0.4rem);
    border: solid 2px #fff;
    border-radius: 0.08rem;
    margin-bottom: 0.17rem;

    input {
      flex: auto;
      // margin: 0.08rem 0.18rem;
      @include -media($pc, margin, 0.08rem 0.18rem);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      line-height: 0.4rem;
      @include -media($pc, line-height, 0.21rem);
      @include -padding-left(0.2rem, 0.2rem, 0);
      background-color: transparent;
      border: none;
      color: #fff;
    }
  }

  .addr {
    display: flex;
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    line-height: 0.4rem;
    @include -media($pc, line-height, 0.21rem);
    padding: 0.1rem 0;
    i {
      margin-left: 0.2rem;
    }
  }

  .count-container {
    @include flexPos(flex-start);
    margin: 0.17rem 0 0.24rem;

    button {
      width: 0.31rem;
      height: 0.31rem;
      line-height: 0.28rem;
      border-radius: 50%;
      border: solid 0.02rem #fff;
      cursor: pointer;
      background-color: transparent;
      font-size: 0.3rem;
      // @include flexPos(center);

      &.minus {
        line-height: 0;
      }
    }

    .num {
      margin: 0 0.36rem;
      font-size: 0.36rem;
    }
  }

  .set-price-container {
    display: flex;
    @include -width(100%, 100%, 6.84rem);
    @include -height(0.8rem, 0.8rem, 0.4rem);
    border: solid 2px #fff;
    border-radius: 0.08rem;
    margin-top: 0.17rem;

    input {
      flex: auto;
      // margin: 0.08rem 0.18rem;
      @include -media($pc, margin, 0.08rem 0.18rem);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      line-height: 0.4rem;
      @include -media($pc, line-height, 0.21rem);
      @include -padding-left(0.2rem, 0.2rem, 0);
      background-color: transparent;
      border: none;
      color: #fff;
    }

    .select-wrap {
      flex: 0 0 1.41rem;
      @include -width(2.34rem, 2.34rem, 1.41rem);
      height: 100%;
      border-radius: 0.08rem;
      padding: 0.08rem 0.17rem;
      @include flexPos(space-between);
      font-size: 0.16rem;
      position: relative;
      cursor: pointer;
      border: none;
      border-left: solid 1.5px #fff;
      color: #fff;

      .iconfont {
        font-size: 0.25rem;

        &.icon-simple-arrow {
          transform: rotate(-90deg);
          font-size: 0.2rem;
        }
      }
      span {
        padding: 0 0.12rem;
        @include -media($pc, padding, 0);
      }

      .option-wrap {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0.68rem;
        @include -media($pc, top, 0.38rem);
        padding: 0.06rem 0.12rem;
        border-radius: 5px;
        border: solid 1.5px #fff;
        background-color: #090120;

        li {
          padding: 0.1rem 0;
          @include flexPos(flex-start);

          .iconfont {
            margin-right: 0.12rem;
          }

          &:not(:last-child) {
            border-bottom: solid 1.2px #a4a4a4;
          }
        }
      }
    }
  }

  .need-tips {
    @include -width(100%, 100%, 6.84rem);
    height: 0.4rem;
    @include flexPos(flex-end);

    > div {
      @include flexPos(center);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      line-height: 0.4rem;
      @include -media($pc, line-height, 0.21rem);
    }
  }

  .btns {
    width: 100%;
    @include flexPos(space-between);
    margin-top: 0.5rem;

    button {
      width: 3.14rem;
      height: 0.7rem;
      border-radius: 0.25rem;
      color: #fff;
      background-color: #5b3bb7;
      @include flexPos(center);
      font-size: 0.36rem;
    }
  }
}
</style>
